import { HybridFunction } from '@src/utils/util';

export const TestPage = () => {
  const handleClick = () => {
    const params = {
      depth: '',
      callback: 'paymentProc',
      params: '202406251437',
    };

    const url = HybridFunction.createUrl('closeWindow', params);

    if (url) {
      window.location.href = url;
    }
  };

  const handleClickPushEnable = () => {
    const params = {
      isEnable: true,
    };

    const url = HybridFunction.createUrl('pushEnable', params);

    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div>
      Test Page
      <button onClick={handleClick}>Close Window</button>
      <button onClick={handleClickPushEnable}>Push Enable</button>
    </div>
  );
};
