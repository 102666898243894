export const HybridFunction: HybridFunctionInterface = {
  BASE_URL: 'hybridfunction://',

  createUrl(MethodName: HybridFunctionMethods, params: HybridFunctionParams = {}): string {
    const queryParams = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return `${this.BASE_URL}${MethodName}${queryParams ? `?${queryParams}` : ''}`;
  },
};
