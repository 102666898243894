import { HybridFunction } from '@src/utils/util';
import { css } from '@emotion/css';

export const BridgePage = () => {
  const handleClick = () => {
    const params = {
      path: 'encodeUrl(https://7e5a-121-130-31-3.ngrok-free.app/test)',
      navi: 1,
      type: 0,
      title: '테스트',
      tintColor: '#ffffff',
    };
    const url = HybridFunction.createUrl('viewOpen', params);

    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div className={styles.container}>
      <button onClick={handleClick}>Open Naver</button>
    </div>
  );
};

const styles = {
  container: css`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  `,
};
