import { css } from '@emotion/css';

export const DownloadPage = () => {
  const handleInstallIOS = () => {
    window.location.href =
      'itms-services://?action=download-manifest&url=https://react-hybrid-webapp.pages.dev/info.plist';
  };

  const handleInstallAndroid = () => {
    window.location.href = 'https://react-hybrid-webapp.pages.dev/akgolf-0719.apk';
  };

  return (
    <div className={DownloadPageStyle}>
      <div>
        <h1>Android 다운로드</h1>
        <button onClick={handleInstallAndroid} className={buttonStyle}>
          안드로이드 인스톨
        </button>
      </div>
      <div>
        <h1>IOS 다운로드</h1>
        <button onClick={handleInstallIOS} className={buttonStyle}>
          iOS 인스톨
        </button>
      </div>
    </div>
  );
};

const DownloadPageStyle = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 60px;
`;

const buttonStyle = css`
  width: 180px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
`;
